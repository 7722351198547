import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faArrowRight, faEnvelope, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  DetailItem,
  Drawer,
  SearchDropdown,
  TextField,
  Tooltip,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder } from '../../../../types';
import { SavedResponse } from '../../../../types/SavedResponse';
import styles from './SendSavedResponseDrawer.module.scss';

interface FormValues {
  selectedResponseId: number;
  title: string;
  message: string;
}

interface Props {
  show?: boolean;
  onClose: () => void;
  order: ManagerOrder;
  onNewResponse: () => void;
}

const SendSavedResponseDrawer = (props: Props) => {
  const { show, onClose, order, onNewResponse } = props;
  const api = useApi();
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const [savedResponses, setSavedResponses] = useState<SavedResponse[]>();
  const [sendResponseLoading, setSendResponseLoading] = useState(false);
  const { showErrorToast } = useToast();

  const schema = yup.object().shape({
    selectedResponseId: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_saved_responses_saved_response_label,
          }),
        },
      ),
    ),
    title: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_saved_responses_title_label,
          }),
        },
      ),
    ),
    message: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_saved_responses_message_label,
          }),
        },
      ),
    ),
  });

  const getSavedResponses = async () => {
    const { data, error } = await api.getSavedResponses();

    if (data) {
      setSavedResponses(data.collection);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (!order.owner?.person?.email) return;

    const { error } = await api.sendSavedResponse(
      order.id.toString(),
      {
        recipientEmail: order.owner?.person?.email,
        savedResponseId: values.selectedResponseId,
        emailTitle: values.title,
        emailContent: values.message,
      },
      setSendResponseLoading,
    );

    if (error) {
      showErrorToast(error);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      getSavedResponses();
    }
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_saved_responses_label })}
      data-testid="send-saved-responses-drawer"
    >
      <Formik<FormValues>
        initialValues={{ selectedResponseId: -1, title: '', message: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, handleChange, handleBlur, values, setValues, touched, errors }) => (
          <Form className={styles.sendSavedResponse} onSubmit={handleSubmit}>
            <div className={styles.overview}>
              <span className={styles.id}>#{order.id}</span>
              <div className={styles.title}>
                <span>{getLanguageById(order.sourceLanguageId)}</span>
                <FontAwesomeIcon icon={faArrowRight} />
                <span>
                  {order.tasks.map((task) => getLanguageById(task.targetLanguageId)).join(', ')}
                </span>
              </div>
              <span className={styles.description}>
                <FormattedMessage
                  id={translationKeys.translation_order_saved_responses_order_label}
                />
              </span>
            </div>

            <DetailItem
              title={order.owner?.person?.name}
              subtitle={order.enterprise?.name}
              size="large"
              preview={<Avatar size="medium" altText="" url={order.owner?.person?.avatar} />}
            >
              {order.owner?.person?.email && (
                <Tooltip title={order.owner?.person?.email} copyable>
                  <div className={styles.detailsItemDetails}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>{order.owner?.person?.email}</span>
                  </div>
                </Tooltip>
              )}
            </DetailItem>

            <div>
              <span className={styles.selectRowLabel}>
                <FormattedMessage
                  id={translationKeys.translation_order_saved_responses_saved_response_label}
                />
                *
              </span>
              <div className={styles.selectRow}>
                <SearchDropdown
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_order_saved_responses_saved_response_placeholder,
                  })}
                  size="large"
                  onChange={(item) => {
                    if (item && item.length > 0 && item[0].key) {
                      const selectedResp = savedResponses?.find(
                        (response) => response.id === item[0].id,
                      );
                      selectedResp &&
                        setValues({
                          selectedResponseId: item[0].id,
                          title: selectedResp.emailTitle,
                          message: selectedResp.emailContent,
                        });
                    }
                  }}
                  options={
                    savedResponses?.map((item) => ({
                      id: item.id,
                      label: item.name,
                      key: item.id.toString(),
                    })) || []
                  }
                  errorText={touched.selectedResponseId ? errors.selectedResponseId : undefined}
                />

                <Button
                  variant="gray"
                  size="large"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={onNewResponse}
                  className={styles.newResponseButton}
                >
                  <FormattedMessage
                    id={translationKeys.translation_order_saved_responses_new_response_button}
                  />
                </Button>
              </div>
            </div>

            <TextField
              label={intl.formatMessage({
                id: translationKeys.translation_order_saved_responses_title_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_saved_responses_title_placeholder,
              })}
              required
              size="large"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              errorText={touched.title ? errors.title : undefined}
            />

            <TextField
              label={intl.formatMessage({
                id: translationKeys.translation_order_saved_responses_message_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_saved_responses_message_placeholder,
              })}
              required
              size="large"
              textArea
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              errorText={touched.message ? errors.message : undefined}
            />

            <div className={styles.actions}>
              <Button variant="gray" size="large" onClick={onClose}>
                <FormattedMessage
                  id={translationKeys.translation_order_saved_responses_cancel_button}
                />
              </Button>
              <Button size="large" type="submit" isLoading={sendResponseLoading}>
                <FormattedMessage
                  id={translationKeys.translation_order_saved_responses_send_button}
                />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default SendSavedResponseDrawer;
