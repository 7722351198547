import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ManagerJobDowngradeRequirement } from '../../../types/ManagerJob';
import { ManagerJobUpdateStatement } from '../../../types/ManagerJobStatement';
import { systemQueryKeys } from '../System/System.hooks';
import { Jobs } from '..';

export const jobQueryKeys = {
  all: ['jobs'] as const,
  job: (id?: string) => (id ? ([...jobQueryKeys.all, id] as const) : jobQueryKeys.all),
  jobStatement: (id?: string) =>
    id ? ([...jobQueryKeys.all, id, 'statement'] as const) : jobQueryKeys.all,
};

export const useCreateJobMutation = () =>
  useMutation({
    mutationFn: (body: FormData) => Jobs.createJob(body),
  });

export const useGetJobQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.job(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJob(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const useGetJobStatementQuery = (id?: string) =>
  useQuery({
    queryKey: jobQueryKeys.jobStatement(id),
    queryFn: () => {
      if (!id) return null;
      return Jobs.getJobStatement(id).then((res) => res.data);
    },
  });

export const useUpdateJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, body }: { id: number; body: FormData }) => Jobs.updateJob(id, body),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useLockJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => Jobs.lockJob(id),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useDowngradeJobRequirementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      requirement,
    }: {
      id: string;
      requirement: ManagerJobDowngradeRequirement;
    }) => Jobs.downgradeJobRequirement(id, requirement),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(variables.id.toString()),
      });
    },
  });
};

export const useUpdateJobStatementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      statementId,
      statement,
    }: {
      id: string;
      statementId: string;
      statement: Partial<ManagerJobUpdateStatement>;
    }) => Jobs.updateJobStatement(id, statementId, statement),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.jobStatement(variables.id.toString()),
      });
    },
  });
};

export const useCancelJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      Jobs.cancelJob(id, { reason: 'dangerous_fields_edited', other: false }),
    onSuccess(_data, id) {
      queryClient.invalidateQueries({
        queryKey: jobQueryKeys.job(id.toString()),
      });
    },
  });
};

export const useCheckForDuplicateJobIdsMutation = () => {
  return useMutation({
    mutationFn: (body: FormData) => Jobs.checkForDuplicateJobIds(body),
  });
};
