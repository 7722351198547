import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faClone, faEnvelope, faPen, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Tooltip } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrder } from '../../types';
import styles from './OrderDetails.module.scss';

interface Props {
  order: ManagerOrder;
  onEditBooker: () => void;
}

export const BookerCard = ({ order, onEditBooker }: Props) => {
  return (
    <div className={styles.section} data-testid={'order-details-booker-section'}>
      <span className={styles.title}>
        <FontAwesomeIcon className={styles.icon} icon={faUser} />
        <span data-testid="booker-header">
          {order.isLoggedOut && !order.owner?.person?.uid ? (
            <FormattedMessage
              id={translationKeys.translation_order_details_logout_details_header}
            />
          ) : (
            <FormattedMessage id={translationKeys.translation_order_details_booker_header} />
          )}
        </span>

        <Button
          variant="transparent"
          size="large"
          icon={<FontAwesomeIcon className={styles.icon} icon={faPen} />}
          className={styles.action}
          onClick={onEditBooker}
          data-testid="booker-edit-button"
          disabled={order.isLoggedOut && !order.owner?.person?.uid}
        >
          <FormattedMessage id={translationKeys.translation_order_details_section_edit_button} />
        </Button>
      </span>
      <div className={styles.card}>
        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage id={translationKeys.translation_order_details_booker_name_label} />
            </span>
            <span className={styles.value} data-testid="booker-name">
              {order.owner?.person?.name || '-'}
            </span>
          </div>

          {order.owner?.person?.avatar && (
            <Avatar size="medium" altText={order.owner?.person?.name || ''} url={order.owner?.person?.avatar} />
          )}
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_booker_enterprise_label}
              />
            </span>
            <span className={styles.value} data-testid="booker-enterprise">
              {order.enterprise?.name || '-'}
            </span>
          </div>
          {order.enterprise?.logoUrl && (
            <Avatar
              size="medium"
              altText={order.enterprise?.name || ''}
              url={order.enterprise?.logoUrl}
            />
          )}
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_booker_department_label}
              />
            </span>
            <span className={styles.value} data-testid="booker-department">
              {order.department?.name || '-'}
            </span>
          </div>
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage id={translationKeys.translation_order_details_booker_email_label} />
            </span>
            <span className={styles.value} data-testid="booker-email">
              {order.owner?.person?.email || '-'}
            </span>
          </div>
          {order.owner?.person?.email && (
            <div className={styles.tooltip}>
              <Tooltip title={order.owner?.person?.email} copyable>
                <FontAwesomeIcon icon={faEnvelope} />
              </Tooltip>
            </div>
          )}
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage id={translationKeys.translation_order_details_booker_phone_label} />
            </span>
            <span className={styles.value} data-testid="booker-phone">
              {order.owner?.person?.phone || '-'}
            </span>
          </div>
          {order.owner?.person?.phone && (
            <div className={styles.tooltip}>
              <Tooltip title={order.owner?.person?.phone} copyable>
                <FontAwesomeIcon icon={faClone} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
